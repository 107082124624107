.page-item {
  margin: 0 3px;

  a {
    border-radius: 5px;
  }
}

.gridjs-footer {
  display: flex;
  justify-content: space-between;
}
